// Redesign:
$main: #293853;
$main-hover: #293853cc;
$main-bright: #717BDA;
$dark-bright: #555FC0;

$bg-white: #FFFFFF;
$bg-white-semi: #F8F9FF;

$white: #FDFDFD;
$white2: #F4F5FC;
$white3: #FAFBFC;
$white4: #F3F5F8;

$new-hover: #CAD3FF;
$new-background: #E0E6FF;
$preview-background: #E3E8EF;
$darker-background: #F2F2F2;
$new-helper: #E1E7EE;
$new-light: #A6AEB7;

$gray: #9CAEC5;
$gray2: #A8ADB4;
$gray3: #4C525C;
$gray4: #DBDEE2;

$highlight: #606DE4;
$not: #F2DBE0;
$not2: #E05679;
$not-hover: #F3CBD3;
$green: #07B38D;
$link: #5360D9;
$yellow: #EEAC02;

$borage-blue: #525ECC;
$borage-blue-hover: #5F6ACE;
$carte-blanche: #EEF1FF;
$red: #F23465;

// Base colors
$light: #FEFEFF;
$darken-light: #E5E5E5;
$dark: #2A272A;

// Bright colors
$feathers: #60458C;
$wings: #07B38D;
$beaks: #FFCE2E;

$light-feathers: #c1c0ea80;
$light-wings: #a2d4c880;
$light-beaks: #ffd47480;

$hover: #00BAB5;

// Grayscale
$background: #FAFAFA;
$border: #DBE3EE;
$neutral: #AFB7C0;
$helper: #757986;

// System
$anger: #E34464;

// other:
$scroll: #AFB7C0;

$c-green: #58C6C4;
$c-green-grapple: #40B7B5;

$c-cold-lips: #9BA2E5;
$c-cold-lips-hover: #aeb4ea;

$c-pluto: #31A9A7;
$c-pluto-hover: #3CBDBB;

$c-error: #E05679;