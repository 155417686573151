// Font awesome
@import "fontawesome/scss/fontawesome";
@import "fontawesome/scss/brands";

// Proxima fonts:
@font-face {
  font-family: 'Proxima Nova Bold';
  src: url('ProximaNova-Bold.woff2') format('woff2'),
  url('ProximaNova-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// todo: remove if use loadNonCriticalFonts approach
//@font-face {
//  font-family: 'Proxima Nova Bold Italic';
//  src: url('ProximaNova-BoldIt.woff2') format('woff2'),
//  url('ProximaNova-BoldIt.woff') format('woff');
//  font-weight: 700;
//  font-style: normal;
//  font-display: swap;
//}

@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url('ProximaNova-Semibold.woff2') format('woff2'),
  url('ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Medium';
  src: url('ProximaNova-Medium.woff2') format('woff2'),
  url('ProximaNova-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Regular.woff2') format('woff2'),
  url('ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
