h1 {
  font-family: $bold-base-font;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

h2 {
  font-family: $semibold-base-font;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

h4 {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}

h5 {
  font-family: $highlight-font;
  font-style: italic;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
}

h6 {
  font-family: $bold-base-font;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}

.h7 {
  line-height: 23px;
}

.clickable {
  cursor: pointer;
}

.hide {
  display: none!important;
}

.hide-opacity {
  cursor: default;
  pointer-events: none;
  user-select: none;
  opacity: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.full-width {
  width: 100%;
}

.flex-full-width {
  display: flex;
  width: 100%;
}

.text-with-bold-span span {
  font-family: $bold-base-font;
}

.text-with-bold-highlight-span span {
  font-family: $highlight-font;
}

.pre-line {
  white-space: pre-line;
}

.bold {
  font-family: $bold-base-font;
}

.preview-block {
  display: flex;
  background: $border;
  border-radius: 4px;
}

.with-inner-facet {
  word-break: break-word;

  .facet {
    margin-left: 10px;
  }
}

ul.list {
  list-style: none;
  padding: 0;

  li.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    &.active {
      cursor: default;
    }
  }

  &.with-background {
    background: $white;
    li.item {
      &__text {
        color: $main;
      }
      &.active .item__text {
        color: $highlight;
      }
      &:hover {
        background: #F3F5F8;
      }
    }
  }

  &.dropdown-list {
    border: 1px solid $new-helper;
    border-radius: 3px;
    list-style: none;
    padding: 5px 0;
    margin: 0;
    height: fit-content;

    .item {
      padding: 5px 11px;

      &__text {
        font-family: $bold-base-font;
        font-style: normal;
        font-weight: normal;;
        font-size: 16px;
        line-height: 19px;
      }

      &.active {
        .item__text {
          margin-right: 8px;
        }

        &:after {
          content: '';
          display: flex;
          background-image: url("~src/assets/images/icons/done.svg");
          width: 14px;
          height: 14px;
        }
      }

      & + .item {
        margin-top: 5px;
      }
    }

    &.small {
      padding: 7px 0;

      .item {
        padding: 8px 19px;

        & + .item {
          margin-top: 8px;
        }
      }

      .item__text {
        font-size: 14px;
        line-height: 17px;
      }

      &.active {
        .item__text {
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: $laptop-small) {
  .system-global-page {
    opacity: 0;
  }

  .only-desktop {
    display: none!important;
  }
}
