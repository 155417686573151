// Font size (fonts, headings, bodycopy)
$base-font: 'Proxima Nova';
$highlight-font: 'Proxima Nova Bold Italic';
$medium-base-font: 'Proxima Nova Medium';
$semibold-base-font: 'Proxima Nova Semibold';
$bold-base-font: 'Proxima Nova Bold';

// Icon font path
$fa-font-path: '../fonts/fontawesome/webfonts';

// Sizing
$regular-text: 16px;
$regular-line-height: 28px;

$large-text: 18px;
$large-line-height: 32px;

$small-text: 14px;
$small-line-height: 24px;

$tiny-text: 12px;
$tiny-line-height: 16px;
